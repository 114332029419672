<template>
  <tbody>
    <tr v-if="tableRows.length === 0">
      <td
        colspan="6"
        class="bg-white"
      >
        <div class="mx-auto my-40 flex flex-col items-center justify-center">
          <img
            class="h-50"
            src="/content/images/brightbid/sad-bot.svg"
            alt=""
          />
          <p class="text-xl my-2">No competitors found...</p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(item, index) in tableRows"
      v-else
      :key="index"
      class="whitespace-no-wrap cursor-pointer font-normal w-full text-sm z-10 transform transition-height duration-200 ease-linear"
      :class="[
        hoveredRow === item.id && item.parent ? 'bg-bb-background-purple-0' : 'bg-white',
        item.parent ? 'h-16' : 'h-0 ',
        selectedRow === item.id && !item.parent ? 'max-h-140 bg-white' : 'h-0',
        selectedRow === item.id && item.parent ? 'bg-bb-background-purple-0' : '',
      ]"
      @click="expandRow(item.id, item.parent)"
      @mouseenter="hoveredRow = item.id"
      @mouseleave="hoveredRow = null"
    >
      <td
        v-if="item.parent"
        class="px-4 py-2 whitespace-no-wrap sticky left-0 w-full duration-200 ease-linear"
        :class="[
          hoveredRow === item.id ? 'bg-bb-background-purple-0' : 'bg-white',
          selectedRow === item.id ? 'border-l-4 border-bb-brand-purple' : 'border-l-4 border-transparent',
          selectedRow === item.id ? 'bg-bb-background-purple-0' : '',
        ]"
      >
        <div class="flex items-start gap-2">
          <img
            :src="`https://www.google.com/s2/favicons?domain=${item.url}`"
            class="pt-1"
          />
          <div class="text-xs">
            <p class="font-bold text-sm text-bb-text-headers">{{ item.competitorName }}</p>
            <a
              :href="item.url"
              target="_blank"
              @click.stop
            >
              <p class="text-bb-brand-purple text-sm font-normal">{{ item.url }}</p>
            </a>
          </div>
        </div>
      </td>
      <td
        v-if="item.parent"
        class="px-4"
      >
        <div class="w-40">
          <p
            class="px-4 py-1 mx-auto w-fit rounded-full text-sm font-bold"
            :class="item.adVariationStyle"
          >
            {{ item.adVariationsCount }}
          </p>
        </div>
      </td>
      <td
        v-if="item.parent"
        class="px-4"
      >
        <div class="flex items-center gap-2 w-40 justify-center">
          <progress-bar
            :value="item.topPagePercentage"
            :progress-mark="101"
            :show-percent-label="false"
            use-solid-color
            container-width="w-15"
            container-height="12px"
            class="flex"
          ></progress-bar>
          <p class="text-sm font-medium text-right whitespace-no-wrap">{{ item.topPagePercentage || 0 }}%</p>
        </div>
      </td>

      <td
        v-if="item.parent"
        class="px-4"
      >
        <div class="w-40">
          <p
            class="px-4 py-1 mx-auto w-fit rounded-full text-sm font-bold"
            :class="item.adPositionStyle"
          >
            {{ item.avgAdPosition }}
          </p>
        </div>
      </td>
      <td
        v-if="item.parent"
        class="px-4"
      >
        <div class="w-40 text-center">
          <p>{{ item.shareOfSearchPercentage || 0 }}%</p>
        </div>
      </td>
      <td
        v-if="item.parent"
        class="px-4 text-right"
      >
        <ic-chevron
          class="duration-200 ease-linear"
          :direction="selectedRow === item.id ? 'up' : 'down'"
          :class="hoveredRow === item.id || selectedRow === item.id ? 'text-bb-brand-purple' : 'text-white'"
        />
      </td>
      <td
        v-if="!item.parent && selectedRow === item.id"
        colspan="6"
        class="duration-200 ease-linear border-l-4 border-bb-brand-purple h-200"
      >
        <div class="py-6 px-10">
          <ad-variations-table
            :open-pagination-below="false"
            :variations="item.adVariations || []"
          />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import ProgressBar from '@/components/shared/ProgressBar.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import AdVariationsTable from '@/views/site/search/competitor_monitoring_v2/competitors/table/ad_variations/AdVariationsTable.vue'

export default {
  name: 'AllCompetitorsTableRows',
  components: {
    ProgressBar,
    IcChevron,
    AdVariationsTable,
  },
  props: {
    allCompetitors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredRow: null,
      expressionInput: '',
      hasDuplicateKeyword: false,
      maximumLengthError: false,
      minimumLengthError: false,
      isLoading: false,
      selectedRow: null,
    }
  },
  computed: {
    tableRows() {
      const rows = []
      this.allCompetitors.map((item, index) => {
        const identifier = `${item.competitorName}-${item.url}-${index}`

        rows.push({
          ...item,
          parent: true,
          id: identifier,
          avgAdPosition: this.parseAndRemoveZeroes(item.avgAdPosition),
          adVariationStyle: this.applyTagStyle(Math.floor(item.adVariationsCount)),
          adPositionStyle: this.applyTagStyle(Math.floor(item.avgAdPosition)),
        })
        rows.push({
          id: identifier,
          competitorName: item.competitorName,
          parent: false,
          adVariations: item.adVariations,
        })
      })

      return rows
    },
  },
  methods: {
    applyTagStyle(value) {
      switch (value) {
        case 0:
          return 'bg-neutral-50 text-bb-neutral-gray'
        case 1:
          return 'bg-bb-green-50 text-bb-decorative-green'
        case 2:
          return 'bg-bb-decorative-cyan text-bb-dark-cyan'
        case 3:
          return 'bg-bb-warning-100 text-bb-warning-200'
        case 4:
          return 'bg-bb-blush-pink text-bb-dark-pink'
        default:
          return 'bg-bb-blush-pink text-bb-dark-pink'
      }
    },
    parseAndRemoveZeroes(num) {
      return num ? parseFloat(parseFloat(num).toFixed(1)) : 0
    },
    expandRow(id, isParent) {
      if (!isParent) return
      if (this.selectedRow === id) {
        this.selectedRow = null
        return
      }

      this.selectedRow = id
    },
  },
}
</script>
<style scoped lang="scss"></style>
